import { twitterHandle, twitterlink } from "../config";

function Home() {
    return (
        <div class="container-fluid justify-content-center">

        <br></br>      

            <div class="homecontainer">

                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tg60.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons">-<br></br><br></br><br></br><br></br>.</div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tr80.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text lora">Hi, I'm ZK.
                        <p class="overlay-text-secondary">
                            <br></br>
                            I explore, understand, & create.
                            <br></br>
                            This is a space to share that experience.
                            <br></br>
                            <br></br>
                            Reach out: 
                            <br></br>
                             <a className="twitter-follow-button" data-show-count="false" target="_blank" rel="noopener noreferrer" href={twitterlink}>
                                <img src={process.env.PUBLIC_URL+"/icons/twittericon.svg"} className='socialicons' alt=""></img>
                            </a>
                            <a className="" href="mailto:contact@z37k.com" target="_blank" rel="noopener noreferrer">
                                <img src={process.env.PUBLIC_URL+"/icons/emailicon.svg"} className='socialicons' alt=""></img>
                            </a>
                        </p>
                    </div>
                </div>
                <div class="homediv">
                    <img src={process.env.PUBLIC_URL+"/tp50.png"} class="img-fluid" alt="Z37K"></img>
                    <div class="overlay-text-icons text-end">¬ <br></br><br></br><br></br><br></br>..</div>
                </div>
            </div>

        <br></br>

        </div>
    );
  }
  
  export default Home;